import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "100vh" }, attrs: { id: "rounds-bg" } },
    [
      _c("div", { attrs: { id: "rounds-content" } }, [
        _c("div", { staticClass: "rounds-header" }, [
          _c("div", [
            _c("h1", { staticClass: "rounds-title" }, [
              _vm._v(_vm._s(_vm.$t("rounds_title"))),
            ]),
            _c("h2", { staticClass: "rounds-sub-title" }, [
              _vm._v(_vm._s(_vm.$t("rounds_sub_title"))),
            ]),
          ]),
          _c(
            "div",
            [
              _c("NewDxaButton", {
                attrs: {
                  "data-test": "Round:Rounds:BtnCreateNewRound",
                  disabled: _vm.isManagerUser,
                  title: _vm.$t("link_create_new_round"),
                },
                on: { btnFunction: _vm.createNewRound },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "rounds-filter" },
          [
            _c("h3", { staticClass: "rounds-filters-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("filters")) + " "),
            ]),
            _c(
              VForm,
              {
                staticClass: "rounds-filters-items-flex",
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.getRoundsByPage($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "rounds-filters-label" }, [
                      _vm._v(_vm._s(_vm.$t("rounds_company_title"))),
                    ]),
                    _c(VAutocomplete, {
                      staticClass: "rounds-filters-auto-complete",
                      attrs: {
                        items: _vm.roundsAllCompanies,
                        "item-text": "Name",
                        "item-value": "CompanyId",
                        color: "primary",
                        dense: "",
                        label: _vm.$t("select_label"),
                        outlined: "",
                        "data-test": "Round:Rounds:InputFilterCompany",
                      },
                      model: {
                        value: _vm.roundsSelectedCompany,
                        callback: function ($$v) {
                          _vm.roundsSelectedCompany = $$v
                        },
                        expression: "roundsSelectedCompany",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "rounds-filters-label" }, [
                      _vm._v(_vm._s(_vm.$t("rounds_state_title"))),
                    ]),
                    _c(VAutocomplete, {
                      staticClass: "rounds-filters-auto-complete",
                      attrs: {
                        "data-test": "Round:Rounds:InputFilterStatus",
                        items: _vm.roundStatusItems,
                        "item-text": _vm.translateRoundStatus,
                        "item-value": "value",
                        color: "primary",
                        dense: "",
                        label: _vm.$t("select_label"),
                        outlined: "",
                      },
                      model: {
                        value: _vm.roundsSelectedStatus,
                        callback: function ($$v) {
                          _vm.roundsSelectedStatus = $$v
                        },
                        expression: "roundsSelectedStatus",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", [
                  _c("span", { staticClass: "rounds-filters-label" }, [
                    _vm._v(_vm._s(_vm.$t("rounds_time_title"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "rounds-date-flex-items" },
                    [
                      _c(
                        VMenu,
                        {
                          ref: "startMenuDateRef",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    VTextField,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: ["##/##/####"],
                                              expression: "['##/##/####']",
                                            },
                                          ],
                                          staticClass: "rounds-filters-date",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            color: "#AFAFAF",
                                            "background-color": "#E9E9E9",
                                            dense: "",
                                            outlined: "",
                                            "append-icon": "mdi-calendar",
                                            label: "DD/MM/AA",
                                            height: "34",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              _vm.roundCaptureStartDate =
                                                _vm.formatDateOnBlur(
                                                  _vm.roundCaptureStartDateText
                                                )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.roundCaptureStartDateText,
                                            callback: function ($$v) {
                                              _vm.roundCaptureStartDateText =
                                                $$v
                                            },
                                            expression:
                                              "roundCaptureStartDateText",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.roundStartMenuDate,
                            callback: function ($$v) {
                              _vm.roundStartMenuDate = $$v
                            },
                            expression: "roundStartMenuDate",
                          },
                        },
                        [
                          _c(VDatePicker, {
                            attrs: {
                              locale: "pt-BR",
                              color: "primary",
                              min: "1850-01-01",
                            },
                            on: { change: _vm.saveRoundCaptureStartDate },
                            model: {
                              value: _vm.roundCaptureStartDate,
                              callback: function ($$v) {
                                _vm.roundCaptureStartDate = $$v
                              },
                              expression: "roundCaptureStartDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "rounds-date-item-space-letter" },
                        [_vm._v("a")]
                      ),
                      _c(
                        VMenu,
                        {
                          ref: "endMenuDateRef",
                          attrs: {
                            "close-on-content-click": false,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    VTextField,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: ["##/##/####"],
                                              expression: "['##/##/####']",
                                            },
                                          ],
                                          staticClass: "rounds-filters-date",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            color: "#AFAFAF",
                                            "background-color": "#E9E9E9",
                                            dense: "",
                                            outlined: "",
                                            "append-icon": "mdi-calendar",
                                            label: "DD/MM/AA",
                                            height: "34",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              _vm.roundCaptureFinishDate =
                                                _vm.formatDateOnBlur(
                                                  _vm.roundCaptureFinishDateText
                                                )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.roundCaptureFinishDateText,
                                            callback: function ($$v) {
                                              _vm.roundCaptureFinishDateText =
                                                $$v
                                            },
                                            expression:
                                              "roundCaptureFinishDateText",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.roundEndMenuDate,
                            callback: function ($$v) {
                              _vm.roundEndMenuDate = $$v
                            },
                            expression: "roundEndMenuDate",
                          },
                        },
                        [
                          _c(VDatePicker, {
                            attrs: {
                              locale: "pt-BR",
                              color: "primary",
                              min: "1850-01-01",
                            },
                            on: { change: _vm.saveRoundCaptureEndDate },
                            model: {
                              value: _vm.roundCaptureFinishDate,
                              callback: function ($$v) {
                                _vm.roundCaptureFinishDate = $$v
                              },
                              expression: "roundCaptureFinishDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "rounds-filters-item-button-to-filter-box" },
                  [
                    _c("NewDxaButton", {
                      attrs: {
                        "data-test": "Round:Rounds:ButtonFilterRounds",
                        loading: _vm.loading,
                        type: "submit",
                        outline: true,
                        title: _vm.$tc("filter_kpi", 1),
                      },
                      on: { btnFunction: _vm.getRoundsByPage },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "box-loading" },
              [
                _c(VProgressCircular, {
                  attrs: { indeterminate: "", size: "35", color: "primary" },
                }),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(VSimpleTable, [
                  _c(
                    "thead",
                    { staticClass: "rounds-table-header rounds-table-head" },
                    [
                      _c(
                        "th",
                        [
                          _vm._v(" " + _vm._s(_vm.$t("single_round")) + " "),
                          _c(
                            VIcon,
                            {
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.headerClick(
                                    _vm.orderFilterEnum.ROUND
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderByMode === _vm.orderByModeEnum.ASC
                                    ? "mdi-arrow-up"
                                    : "mdi-arrow-down"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "th",
                        [
                          _vm._v(" " + _vm._s(_vm.$t("company_single")) + " "),
                          _c(
                            VIcon,
                            {
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.headerClick(
                                    _vm.orderFilterEnum.COMPANY
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderByMode === _vm.orderByModeEnum.ASC
                                    ? "mdi-arrow-up"
                                    : "mdi-arrow-down"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "th",
                        [
                          _vm._v(" " + _vm._s(_vm.$t("startDate")) + " "),
                          _c(
                            VIcon,
                            {
                              attrs: { color: "primary", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.headerClick(
                                    _vm.orderFilterEnum.STARTFUNDING
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderByMode === _vm.orderByModeEnum.ASC
                                    ? "mdi-arrow-up"
                                    : "mdi-arrow-down"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "th",
                        [
                          _vm._v(" " + _vm._s(_vm.$t("endDate")) + " "),
                          _c(
                            VIcon,
                            {
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.headerClick(
                                    _vm.orderFilterEnum.ENDFUNDING
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderByMode === _vm.orderByModeEnum.ASC
                                    ? "mdi-arrow-up"
                                    : "mdi-arrow-down"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "th",
                        [
                          _vm._v(" " + _vm._s(_vm.$t("status")) + " "),
                          _c(
                            VIcon,
                            {
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.headerClick(
                                    _vm.orderFilterEnum.STATUS
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderByMode === _vm.orderByModeEnum.ASC
                                    ? "mdi-arrow-up"
                                    : "mdi-arrow-down"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "th",
                        [
                          _vm._v(" " + _vm._s(_vm.$t("capture")) + " "),
                          _c(
                            VIcon,
                            {
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.headerClick(
                                    _vm.orderFilterEnum.CAPTURE
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderByMode === _vm.orderByModeEnum.ASC
                                    ? "mdi-arrow-up"
                                    : "mdi-arrow-down"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("th"),
                      _c("th"),
                    ]
                  ),
                  _c(
                    "tbody",
                    _vm._l(_vm.allRounds, function (item, i) {
                      return _c("tr", { key: i }, [
                        _c("td", { staticClass: "text-center" }, [
                          item.id
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "rounds-table-item rounds-table-item-round-number",
                                },
                                [_vm._v(_vm._s(item.id) + " ")]
                              )
                            : _c("p", [_vm._v("-")]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          item.companyName
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "rounds-table-item rounds-table-item-company-name",
                                },
                                [_vm._v(_vm._s(item.companyName) + " ")]
                              )
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          item.captureStartDate
                            ? _c("span", { staticClass: "rounds-table-item" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDateHelper(item.captureStartDate)
                                  ) + " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          item.captureFinishDate
                            ? _c("span", { staticClass: "rounds-table-item" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDateHelper(item.captureFinishDate)
                                  ) + " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          item.roundStatus
                            ? _c("span", { staticClass: "rounds-table-item" }, [
                                _vm._v(
                                  _vm._s(_vm.roundStatusTable(item)) + " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("span", { staticClass: "rounds-table-item" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formattedPercentage(item.capturePercentage)
                              ) + " "
                            ),
                          ]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "a",
                            {
                              staticClass: "rounds-table-link",
                              attrs: {
                                "data-test": "Round:Rounds:SeeRoundDetails",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.seeRoundDetails(item.id)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { color: "primary" } }, [
                                _vm._v("mdi-eye-outline"),
                              ]),
                              _c("span", [_vm._v(_vm._s(_vm.$t("details")))]),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.user.type == _vm.userTypeEnum.Admin,
                                expression: "user.type == userTypeEnum.Admin",
                              },
                            ],
                            staticClass: "text-center",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "rounds-table-link",
                                on: {
                                  click: function ($event) {
                                    return _vm.seeRoundAllocations(item.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "rounds-table-link-icon-money",
                                  },
                                  [
                                    _c(
                                      VIcon,
                                      { attrs: { color: "primary" } },
                                      [_vm._v("mdi-currency-usd ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("allocations"))),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
                _c(VPagination, {
                  attrs: { color: "primary", length: _vm.pageCount },
                  on: { input: _vm.changePage },
                  model: {
                    value: _vm.page,
                    callback: function ($$v) {
                      _vm.page = $$v
                    },
                    expression: "page",
                  },
                }),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }